
$fa-font-path: "../webfonts";

// Bootstrap
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

$font-path: "/fonts/Akzidenz-Grotesk-BQ";

@font-face {
    font-family: "Akzidenz-Grotesk BQ";
    src: url("#{$font-path}/AkzidenzGroteskBQ-Light.eot");
    src: url("#{$font-path}/AkzidenzGroteskBQ-Light.eot?#iefix")
            format("embedded-opentype"),
        url("#{$font-path}/AkzidenzGroteskBQ-Light.woff2") format("woff2"),
        url("#{$font-path}/AkzidenzGroteskBQ-Light.woff") format("woff"),
        url("#{$font-path}/AkzidenzGroteskBQ-Light.ttf") format("truetype"),
        url("#{$font-path}/AkzidenzGroteskBQ-Light.svg#AkzidenzGroteskBQ-Light")
            format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Akzidenz-Grotesk BQ";
    src: url("#{$font-path}/AkzidenzGroteskBQ-Bold.eot");
    src: url("#{$font-path}/AkzidenzGroteskBQ-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("#{$font-path}/AkzidenzGroteskBQ-Bold.woff2") format("woff2"),
        url("#{$font-path}/AkzidenzGroteskBQ-Bold.woff") format("woff"),
        url("#{$font-path}/AkzidenzGroteskBQ-Bold.ttf") format("truetype"),
        url("#{$font-path}/AkzidenzGroteskBQ-Bold.svg#AkzidenzGroteskBQ-Bold")
            format("svg");
    font-weight: bold;
    font-style: normal;
}

//Custom CSS
// @import "pages/custom";
// @import "pages/pastor/custom_pastor";
// @import "pages/events/conferencias/pastores2019/custom_pastores";
